import React, { useCallback, useState, useEffect } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { mapRules } from './data';
import axios from 'axios';
import Loading from './Loading';
import { getLambdaUrl, getAvailableRules } from './utils';
import { Question, Youtube } from 'react-bootstrap-icons';

const Read = (props) => {

  const { ReactGA } = props;
  const [loading, setLoading] = useState(true);  
  const [tokens, setTokens] = useState([]);

  const reloadRule = useCallback(async() => {
    setLoading(true);
    const arules = getAvailableRules(props.data)
    const response = await axios
      .get(getLambdaUrl(props.data, arules))
    setTokens(response.data.tokens);
    setLoading(false); 
  }, [setTokens, props.data])

  useEffect(() => {
    const fetchData = async () => {
      await reloadRule();
    }
    fetchData();
}, [reloadRule]); // Or [] if effect doesn't need props or state

  function handleClick(rule) {
    ReactGA.event({
      category: 'Learn',
      action: rule.rule,
      value: 1
    });
    window.open(rule.video);
  }

  function showLegend() {
    const body = (<><p>Each rule is shown below. Click on the Youtube icon to view a short video about the rule.</p><ButtonGroup vertical>
      {mapRules.map((rule) => {
        return <><Button onClick={()=> handleClick(rule)} style={{background: rule.color, textAlign: 'left'}}>{rule.title}&nbsp;&nbsp;&nbsp;<Youtube/></Button></>
      })}
      </ButtonGroup></>)
    props.showModalWithMessage('Tajweed Rules', body, null, null, null, true);
  }

  return loading ? <Loading/> : (
    <>     
      <Button class="pull-right" onClick={() => showLegend(true)}>
        <Question/>
      </Button>
      
    <div className="questionArea">
      <div className="question">
      {tokens && tokens.map((x,index) => {
        const rule = mapRules.find(r => r.rule === x.rule);
        return <span key={`q${index}`} id={`q${index}`} style={{color: rule && rule.color}}>{x.text}</span>
      })}
      </div>
    </div>
    </>
  );
}

export default Read;
