export const mapRules = [{
	rule: `ghunnah`,
	title:  'Ghunnah',
	video: `https://www.youtube.com/watch?v=RthQJC6p9Hg`,
    color: `#24980b`
}, {
	rule: `madda_permissible`,
	title:  'Madd Arid',
	video: `https://www.youtube.com/watch?v=KAn4ECxAaqk`,
	color: `#ff7600`
}, {
	rule: `laam_shamsiyah`,
	title:  'Lam Shamsiyyah',
	video: `https://www.youtube.com/watch?v=uae_6YcB31I`,
    color: `#929292`
}, {
    rule: `madda_necessary`,
	title:  'Madd Munfasil (Short)',
	video: `https://www.youtube.com/watch?v=GHeIe7tnDlM`,
    color: `#bc2ceb`
}, 	{
	rule: `madda_obligatory`,
	title:  'Madd Muttasil (Long)',
	video: `https://www.youtube.com/watch?v=OJ2GYsv66r4`,
    color: `#892e39`
},	  {
	rule: `idgham_ghunnah`,
	title: 'Idgham - With Ghunnah',
	video: `https://www.youtube.com/watch?v=As33O6gcBjg`,
    color: `#929292`
},	{
	rule: `idgham_wo_ghunnah`, 
    title: 'Idgham - Without Ghunnah',
	video: `https://www.youtube.com/watch?v=Kx8rN90wLGc`,
    color: `#929292`
},{
	rule: `idgham_shafawi`,
	title:  'Idgham Shafawi',
	video: `https://www.youtube.com/watch?v=wSiiJN6Mtqk`,
    color: `#929292`
},{
	rule: `ikhafa`,
	title:  `Ikhfa'`,
	video: `https://www.youtube.com/watch?v=KL1H75QX5p0`,
    color: `#24980b`
},{
	rule: `ikhafa_shafawi`,
	title:  `Ikhfa' Shafawi`,
	video: `https://www.youtube.com/watch?v=ZAM2YHzvGak`,
    color: `#929292`
},{
	rule: `iqlab`,
	title:  'Qalb',
	video: `https://www.youtube.com/watch?v=tMNoAMKOhYo`,
    color: `#24980b`
},  {
	rule: `qalaqah`,
	title:  'Qalqalah',
	video: `https://www.youtube.com/watch?v=djsZuqvnvcc`,
    color: `#027bfd`
}]