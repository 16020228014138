import React,  { useState, useEffect, useRef, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import fireworks from 'fireworks';
import { howManyTries, getLambdaUrl, getAvailableRules } from './utils';
import axios from 'axios';
import Loading from './Loading';
import { Question, Youtube } from 'react-bootstrap-icons';
import { translate, calculateBadge } from "./utils";

const FindO = (props) => {
  
  const { ReactGA, showModalWithMessage, data, updateCookie } = props;
  const [ inited, setInited ] = useState(false);
  const maxAnswers = 3;
  const totalQuestions = 5;
  const [loading, setLoading] = useState(true);  
  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()  

  const [tokens, setTokens] = useState([]);
  const [questionNo, setQuestionNo] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [rule, setRule] = useState(null);
  const [questionClasses, setQuestionClasses] = useState('question');
  const [scores] = useState([]);
  const [tries, setTries] = useState(1);
  const [canStillAnswer, setCanStillAnswer]= useState(true);
  
  const reloadRule = useCallback(async() => {
    setLoading(true);
    let existingRule = rule;
    if (!existingRule) {
      const arule = getAvailableRules(data)
      existingRule = arule[Math.floor(Math.random() * arule.length)];
      setRule(existingRule)    
    }
    const response = await axios
      .get(getLambdaUrl(data, [existingRule]))
    setTokens(response.data.tokens);
    setTries(1);
    setQuestionNo(questionNo+1)
    setCanStillAnswer(true)
    setSelectedIndex([])
    setLoading(false); 
  }, [setQuestionNo, questionNo, setTokens, data, rule, setRule])

  useEffect(() => {
    const fetchData = async () => {
      await reloadRule();
    }
    if (!inited) {
      setInited(true);
      const source = data.level === '0' ? 'Juz Amma' : 'the Quran';
      showModalWithMessage(`Practice rule`, translate(data,`You will be tested on a single rule. There will be 5 questions and you will have 3 tries to find an example of the rule. The ayats will come from %SOURCE%. If you need help, click on the ? icon to watch a short video about the rule.`,false, {source:source}), true, () => fetchData(), 'Start');
    }    
}, [inited, setInited, reloadRule, showModalWithMessage, data]); // Or [] if effect doesn't need props or state

  function submitAnswer() {
    setTries(tries+1)
    if (tokens[selectedIndex[0]].rule === rule.rule) {
      setSelectedIndex([]);
      window.scrollTo(0, 0);
      scores.push(tries)
      for (let i = 0; i < 8; i++) {
        const xrand = Math.floor(Math.random() * 100);
        const yrand = Math.floor(Math.random() * 50);
        fireworks({
          x: window.innerWidth * (xrand/100),
          y: window.innerHeight * (yrand/100),
          count: 250,
          bubbleSizeMaximum: 20,
          colors: ['#cc3333', '#4CAF50', '#81C784']
        })
      }
      props.showResponse(true);
      setTimeout(() => {
        goNext();
      }, 1000);
    }
    else {      
      setQuestionClasses("question animated");
      setTimeout(() => {
        setSelectedIndex([]);
        setQuestionClasses("question");
      }, 1000);
    }
  }

  function handleClick(rule) {
    ReactGA.event({
      category: 'Learn',
      action: rule.rule,
      value: 1
    });
    window.open(rule.video);
  }

  function showLegend() {
    const body = (<><p>{translate(props.data,`Here is a short video explaining the rule:`)}</p><ButtonGroup vertical>
      <Button onClick={()=> handleClick(rule)} style={{background: rule.color, textAlign: 'left'}}>{rule.title}&nbsp;&nbsp;&nbsp;<Youtube/></Button>
      </ButtonGroup></>)
    props.showModalWithMessage(rule.title, body, null, null, null, true);
  }

  function goNext() {
    if (questionNo === totalQuestions) {
      // const totalTries = scores.reduce((a, b) => {
      //   return a + b;
      // }, 0);
      const correct = scores.filter(x => x !== -1);
      ReactGA.event({
        category: 'Finished',
        action: 'Find',
        value: 1
      });
      const { title, message } = calculateBadge(data, updateCookie);
      if (correct.length === totalQuestions) {
        const extra = props.data.level === '0' ? translate(data,' Increase the level to Advanced to further test your skills') : '';
        const message = translate(data,`You answered all %TOTAL% questions correctly!`, false, {total: totalQuestions})
        props.showModalWithMessage('Finished!', (message + extra), false, () => props.selectMenu(null, title, message));
      }
      else {
        props.showModalWithMessage('Finished!', translate(data,`You correctly answered %CORRECT% out of %TOTAL% questions.`,false, {correct: correct.length, total: totalQuestions}), false, () => props.selectMenu(null, title, message));
      }      
    }
    else {
      reloadRule(false);
    }
  }

  function selectSpan(index) {
    if (tries < maxAnswers) {
      if (index === -1 || index === selectedIndex[0]) {
        window.scrollTo(0,0)
        setSelectedIndex([]);
      }
      else if (tokens[index].rule) {
        setSelectedIndex([index]);
        executeScroll();
      }
      else {
        setTries(tries + 1);
        setQuestionClasses("question animated");
        setTimeout(() => {
          setSelectedIndex([]);
          setQuestionClasses("question");
        }, 1000);
      }
    }
    else {
      window.scrollTo(0, 0)
      setCanStillAnswer(false)
      scores.push(-1)
      let responses = [];
      tokens.forEach((token, index) => {
        if (token.rule === rule.rule) {
          responses.push(index);
        }
      })
      setSelectedIndex(responses);
    }
  }
  const questionText = rule && rule.title
  const showCorrect = translate(data,`Examples of the correct answer are shown below.`)

  return loading ? <Loading/> : (<>
    <Button class="pull-right" onClick={() => showLegend(true)}>
        <Question/>
      </Button>
      <Container>     
    <div className="questionArea">
      <h2>{questionText}</h2>      
      <h4>({questionNo}/{totalQuestions}) {translate(data,`Please find an example of the rule in the following ayah:`)}</h4>      
      {canStillAnswer ? <p>{howManyTries(data, maxAnswers-tries+1)}</p> : showCorrect    }
      <div className={questionClasses} id="questionDiv">
      {tokens && tokens.map((x,index) => {
        let classes = `text`;
        if (canStillAnswer) {
          if (selectedIndex.indexOf(index) !== -1) {
            classes+=' selected';
          }
        }
        else {
          if (selectedIndex.indexOf(index) !== -1) {
            classes+=' selectedr';
          }
        }
        return <span key={`q${index}`} id={`q${index}`} className={classes} onClick={() => selectSpan(index)}>{x.text}</span>
      })}
      </div>
      {canStillAnswer 
        ? <>
            {selectedIndex.length > 0 && <Button className="m-3" variant="secondary" onClick={() => selectSpan(-1)}>Clear</Button>}
            {selectedIndex.length > 0 && <Button variant="primary" onClick={submitAnswer}>Submit</Button>}        
          </>
        : <Button variant="primary" onClick={goNext}>{translate(data, questionNo === totalQuestions ? `Finish` : `Next Question`)}</Button>}
      </div> 
    </Container>
      <div className="bottom" ref={myRef}>
      <span></span>
    </div>
    </>
);
}

export default FindO;
