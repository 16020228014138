import React from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { mapRules } from './data';
import { translate } from './utils';
import Container from 'react-bootstrap/Container';

const Settings = (props) => {

  const { ReactGA, data } = props;

  function updateLevel(level) {
    props.data.level = level;
    props.updateCookie(props.data);
    ReactGA.event({
      category: 'Level',
      action: level,
      value: 1
    });
  }

  function updateLanguage(language) {
    props.data.lang = language;
    props.updateCookie(props.data);
    ReactGA.event({
      category: 'Language',
      action: language,
      value: 1
    });
  }

  function updateRule(rule) {
    if (!props.data.rules) {
      props.data.rules = {
        [`${rule}`] : false
      }
    }
    else if (props.data.rules[rule] === undefined) {
      props.data.rules[`${rule}`] = false;
    }
    else {
      props.data.rules[rule] = !props.data.rules[rule]

    }
    props.updateCookie(props.data);
    ReactGA.event({
      category: 'Rule',
      action: rule,
      value: 1
    });
  }

  
  
  const checksStyle = {marginLeft: `25px`}
  const checkStyle = {marginBottom:`8px`}

  return (<><br/><br/><Container>  <h2>{translate(data,'Level')}</h2><ButtonGroup>
    <Button onClick={() => updateLevel('0')} variant={props.data.level === '0' ? `primary` : `secondary`}>{translate(data,'Beginner')}</Button>
    <Button onClick={() => updateLevel('1')} variant={props.data.level === '1' ? `primary` : `secondary`}>{translate(data,'Advanced')}</Button>
  </ButtonGroup>    <p>{translate(data,'Beginner level will use ayats from Juz Amma. Advanced will use ayats from the entire Quran.')}</p>
  <br/><br/><h2>{translate(data,'Rules')}</h2><p>{translate(data, 'Choose the rules you would like to be tested on:')}</p>
  <Container style={checksStyle}> 
  {mapRules.map((rule, key) => {
    return <div style={checkStyle}><Form.Check 
    type={'switch'}
    id={`default-checkbox-${key}`}
    key={`default-checkbox-${key}`}
    style={{transform: `scale(1.3)`}}
    checked={!props.data.rules || props.data.rules[rule.rule] === undefined || props.data.rules[rule.rule]}
    onChange={() => {
      updateRule(rule.rule);
    }}
    label={rule.title}
  /></div>
  })}
  </Container> 
  <Container>
  <h2>{translate(data,'Language')}</h2>
  <br/><br/>
  <ButtonGroup>
    <Button onClick={() => updateLanguage('en')} variant={!props.data.lang || props.data.lang === 'en' ? `primary` : `secondary`}>English</Button>
    <Button onClick={() => updateLanguage('fr')} variant={props.data.lang === 'fr' ? `primary` : `secondary`}>Français</Button>
  </ButtonGroup>
  </Container>  
  <br/><br/>
  <Button onClick={() => window.location.href='/'}>Save changes</Button>
</Container>      <div className="bottom">
      <span></span></div>
</>)
}

export default Settings;
