import { mapRules } from './data';
import { language } from './language'

export const getQueryParameters = () => {
    const tokens = window.location.search.substring(1).split("&");
    const result = {}
    tokens.forEach(x => {
      const two = x.split("=")
      if (two.length > 1) {
        result[two[0]] = two.slice(1).join("=")
      }
    })
    return result
  }

export const howManyTries = (data, tries) => {
    return tries > 1 
        ? translate(data,`You've got %TRIES% tries left.`, false, {tries: tries})
        : translate(data, `You've got 1 try left.`);
    
}

export const getLambdaUrl = (data, rules) => {
    const rulesText = rules.length > 0 ? `&rules=${rules.map(x => x.rule).join(",")}` : ``;
    const level = data.level !== undefined && `?level=${data.level}${rulesText}`
    return (process.env.REACT_APP_API_ENV === 'dev'
    ? `https://74xc7z4kef4m2ciaj3qnvp74mq0enavf.lambda-url.eu-west-1.on.aws/`
    : `https://eqmidyohschddyvxziuhqsf7ee0rhfjj.lambda-url.eu-west-1.on.aws/`) + level
}

export const getAvailableRules = (data) => {
    return mapRules.filter(x => {
        return !data.rules || data.rules[x.rule] === undefined || data.rules[x.rule] === true
        // return x.rule === "madda_normal"
    })
}

export const translate = (data, text, isHTML = false, keys = {}) => {
    const entry = language.find(x => x.text === text);
    let result = text;
    if (entry && data) {
        result = entry[data.lang || "en"] || text
    }
    if (Object.keys(keys).length > 0) {
        Object.keys(keys).forEach((key) => {
            result = result.replace(`%${key.toUpperCase()}%`, translate(data, keys[key]));
        })
    }
    return isHTML 
        ? <div dangerouslySetInnerHTML={{__html: result}}/>
        : result
}

export const BADGES = [1, 5, 10, 20, 100];

const isYesterday = (day) => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    return today.getTime() > day;
}

const isYesterdayExact = (day) => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    const yesterday = new Date(today.getTime());
    yesterday.setDate(yesterday.getDate()-1);
    return today.getTime() > day && day > yesterday.getTime();
}

export const calculateBadge = (data, updateCookie) => {
    let checkBadges = false;
    if (data.streak) {
        if (!data.streak.days) {
            data.streak.days = 1; 
            data.streak.lastAchievement = Date.now();
            checkBadges = true;
        }
        else if (isYesterday(data.streak.lastAchievement)) {
            data.streak.days++;
            data.streak.lastAchievement = Date.now();
            checkBadges = true;
        }   
    }
    else {
        data.streak = {
            days: 1,
        };
        checkBadges = true;
    }
    if (checkBadges) {
        updateCookie(data);
        if (data.streak.days === 1) {
            const title = 'Mission Complete!'
            const message = translate(data,'Masha Allah, You have achieved your first badge! To level up, achieve a streak of %NEXTSTREAK%!', false, {nextStreak: BADGES[1]})
            return {
                title, message
            }
        }
        else {            
            const badgeFound = BADGES.findIndex(x => x === data.streak.days);
            if (badgeFound === -1) {
                const nextBadge = BADGES.findIndex(x => x > data.streak.days)
                const title = `You're on track!`
                const message = translate(data,'You have now practiced for %STREAK% days. ',false,{streak: data.streak.days}) + (nextBadge > -1 ? translate(data,`Only %LEFT% days to achieve your next badge.`, false, {left: BADGES[nextBadge] - data.streak.days }) : '')
                return {
                    title, message
                }        
            }
            else {
                const nextBadgeFound = BADGES[badgeFound+1];
                const title = 'Mission Complete!'
                const achievements = `Masha Allah! You have achieved the next badge!`
                const message = nextBadgeFound 
                    ? translate(data,`${achievements} To level up, practice every day for %NEXTSTREAK% days!`, false, {nextStreak: nextBadgeFound})
                    : `Masha Allah, you have achieved all the badges.`                
                updateCookie(data);
                return {
                    title, message
                }        
            }
        }
    }
    return {};
}

export const generateStreakMessage = (data, updateCookie) => {
    if (data && (data.streak !== undefined && isYesterday(data.streak.lastNotice))) {
        const title = 'Welcome Back'
        let moreDays = 1;
        if (data.streak.days) {
            const nextBadge = BADGES.findIndex(x => x > data.streak.days)
            moreDays = BADGES[nextBadge] - data.streak.days;
        }
        if (isYesterdayExact(data.streak.lastAchievement)) {
            const message = translate(data, 'Only %MOREDAYS% days left to level up!', false, {moreDays})
            data.streak.lastNotice = Date.now();
            updateCookie(data);
            return {
                title, message
            }            
        }
        else {
            const previouusBadge = BADGES.findIndex(x => x > data.streak.days) - 1;
            data.streak.days = BADGES[previouusBadge];
            moreDays = previouusBadge > -1 ? BADGES[previouusBadge+1] - data.streak.days : moreDays;
            const message = translate(data, moreDays === 1 ? `Complete your first activity to earn your first badge` : 'Time to restart your streak. Only %MOREDAYS% days left to level up!', false, {moreDays})
            data.streak.lastNotice = Date.now();
            updateCookie(data);
            return {
                title, message
            }            
        }
    }
    return {};
}