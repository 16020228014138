import React,  { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
// import Button from 'react-bootstrap/Button';
import Button from 'react-bootstrap/Button';
import Navbar from './Navbar';
import Menu from './Menu';
import Read from './Read';
import Find from './Find';
import FindO from './FindO';
import { mapRules } from './data';
import Which from './Which';
import Settings from './Settings';
import Help from './Help';
import FindAll from './FindAll';
import { ArrowLeft, CheckCircleFill } from 'react-bootstrap-icons';
import './App.css';
import Modal from './Modal';
import ReactGA from "react-ga4";
import { translate, generateStreakMessage, getQueryParameters } from "./utils";
const version = `1.0.3`
//https://alquran.cloud/tajweed-guide
//https://github.com/dsog/jqurantree
//https://corpus.quran.com/java/example/locationexample.jsp

// const result = {"verseData":"إِ<span  id='q1' class=ghunnah>نّ</span>َ <span  id='q2' class=ham_wasl>ٱ</span>لَّذِينَ لَا يُؤْمِنُونَ بِ<span  id='q3' class=ham_wasl>ٱ</span>لْأَخِرَةِ لَيُسَ<span  id='q4' class=ghunnah>مّ</span>ُونَ <span  id='q5' class=ham_wasl>ٱ</span>لْمَلَ<span  id='q6' class=madda_obligatory>ـٰٓ</span>ئِكَةَ تَسْمِيَةَ <span  id='q7' class=ham_wasl>ٱ</span>لْأُ<span  id='q8' class=ikhafa>نث</span>","rules":[{"index":8,"rule":"ghunnah","text":"نّ"},{"index":39,"rule":"ham_wasl","text":"ٱ"},{"index":95,"rule":"ham_wasl","text":"ٱ"},{"index":141,"rule":"ghunnah","text":"مّ"},{"index":175,"rule":"ham_wasl","text":"ٱ"},{"index":210,"rule":"madda_obligatory","text":"ـٰٓ"},{"index":267,"rule":"ham_wasl","text":"ٱ"},{"index":300,"rule":"ikhafa","text":"نث"}]}

const App = () => {

  const [cookies, setCookie] = useCookies(['appData']);
  const [init, setInit] = useState(false);
  const [menuItem, setMenuItem] = useState('');
  const [modal, showModal] = useState({});
  const [showCorrect, setShowCorrect] = useState({});
  const [toastObject, setToastObject] = useState({visible: false});
  
  const selectMenu = (item, title, message) => {
    ReactGA.event({
      category: item,
      action: 'Menu',
      value: 1
    });
    setMenuItem(item);
    if (title && message) {
      showToast(title, message)
    }
  }

  function showModalWithMessage(title, message, showClose = true, callback, closeTitle, isJSX, onConfirm, confirmTitle) {
    showModal({
      show: true,
      title, 
      message,
      showClose,
      callback,
      appData: cookies.appData,
      closeTitle,
      isJSX,
      onConfirm,
      confirmTitle,
    });
  }

  function showConfirm(title, message, onConfirm, confirmTitle = 'Yes') {
    showModal({
      show: true,
      title, 
      message,
      appData: cookies.appData,
      confirmTitle,
      onConfirm,
    });
  }

  function showToast(title, message) {
    setToastObject({
      title,
      message,
      visible: true,
    })
  }

  function hideToast() {
    setToastObject({
      title: undefined,
      message: undefined,
      visible: false,
    })    
  }

  const showResponse = async (correct) => {
    return new Promise((resolve, _) => {
      setShowCorrect({
        visible: true,
        correct,
      })
      setTimeout(() => {
        setShowCorrect({
          visible: false,
          correct: undefined
        });
        resolve();
      }, 1500);  
    })  
  }

  useEffect(() => {
    const ruleNames = mapRules.map(x => `<li>${x.title}</li>`).join("");
    if (!cookies.welcome) {      
      showModalWithMessage('Welcome to Tajweed App', `This app will test you only on the following rules using ayahs from the Quran: <ul>${ruleNames}</ul>Please check out the Settings menu to customise the app. We also have Streaks! Practice at least once a day to maintain your streak!`, true, () => {
        setCookie('welcome', {init: true, cookies: false, version}, { path: '/' });
        cookies.appData = {
          level: '0', 
          rules: {}, 
          version, 
          lang: 'en',
          streak: {
            lastNotice: Date.now(),
          }
        };
        updateCookie(cookies.appData);
        window.location.reload();
      }, 'Necessary cookies', false, () => {
        cookies.appData = {
          level: '0', 
          rules: {}, 
          version, 
          lang: 'en',
          streak: {
            lastNotice: Date.now(),
          }
        };
        updateCookie(cookies.appData);
        setCookie('welcome', {init: true, cookies: true, version}, { path: '/' });
        window.location.reload();
        hideModal();
      }, 'All Cookies');
    }    
    else if (!init) {
      if (!cookies.welcome.version) {
        cookies.welcome.version = version;
        setCookie('welcome', cookies.welcome);
        showModalWithMessage('Welcome Back!', `We've added 4 new rules. Check out the settings menu where you can manage your preferences including using the app en Français. We've also added Streaks! Practice at least once a day to maintain your streak!`);
        cookies.appData = {
          level: '0', 
          rules: {}, 
          version, 
          lang: 'en',
          streak: {
            lastNotice: Date.now(),
          }          
        };
        updateCookie(cookies.appData);
      }
      else if (cookies.welcome.version === '1.0.1') {
        cookies.welcome.version = version;
        setCookie('welcome', cookies.welcome);
        showModalWithMessage('Welcome Back!', `We've added two new rules (Lam Shamsiyahh and Madd Arid), a new game and translated most of the app in French. We've also added Streaks! Practice at least once a day to maintain your streak!`);
        cookies.appData = {
          level: '0', 
          rules: {}, 
          version, 
          lang: 'en',
          streak: {
            lastNotice: Date.now(),
          }
        };
        updateCookie(cookies.appData);
      }
      else if (cookies.welcome.version === '1.0.2') {
        cookies.welcome.version = version;
        setCookie('welcome', cookies.welcome);
        showModalWithMessage('Welcome Back!', `We've added Streaks! Practice at least once a day to maintain your streak!`);
        cookies.appData = {
          level: '0', 
          rules: {}, 
          version, 
          lang: 'en',
          streak: {
            lastNotice: Date.now(),
          }
        };
        updateCookie(cookies.appData);
      }
      else {
        const queryParams = getQueryParameters();
        if (queryParams) {
          cookies.appData.streak.lastNotice = queryParams.lastNotice ? parseFloat(queryParams.lastNotice) : cookies.appData.streak.lastNotice;
          cookies.appData.streak.lastAchievement = queryParams.lastAchievement ? parseFloat(queryParams.lastAchievement) : cookies.appData.streak.lastAchievement;
          cookies.appData.streak.days = queryParams.days ? parseFloat(queryParams.days) : cookies.appData.streak.days;
        }
        const {title, message} = generateStreakMessage(cookies.appData, updateCookie);
        if (title && message) {
          showModalWithMessage(title, message);
        }
      }
      setInit(true);
      if (cookies.welcome.cookies) {
        ReactGA.initialize('G-NMGN431NXQ');
      }       
    }
    else if (window.location.pathname !== "/" && menuItem !== window.location.pathname.substring(1)) {
      setMenuItem(window.location.pathname.substring(1))
    }
}, [cookies, setCookie, init, setInit, setMenuItem, menuItem]);

  const hideModal = () => {
    showModal({
      show: false,
      title: '', 
      message: ''
    });
  }

  function updateCookie(appData) {
    setCookie('appData', appData, { path: '/' });
  }

  const params = {
    selectMenu,
    showModalWithMessage,
    showResponse,
    updateCookie,
    showToast,
    data: cookies.appData,
    ReactGA
  }
  
  return (
    <>     
      <Navbar data={cookies.appData} {...params} />    
      <Toast show={toastObject.visible} onClose={hideToast}>
      <Toast.Header>
        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
        <strong className="me-auto">{translate(cookies.appData,toastObject.title)}</strong>
      </Toast.Header>
      <Toast.Body>{translate(cookies.appData, toastObject.message)}</Toast.Body>
    </Toast>
      {modal.show && <Modal {...modal} showClose={modal.showClose} onClose={() => {
        hideModal();
        if (modal.callback) modal.callback(false, );
      }}/>}
      {showCorrect.visible && <div style={{border: '1px', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', fontSize: '50px'}}><CheckCircleFill color="green"/></div>}
      {!menuItem 
        ? <Menu onClick={selectMenu} appData={cookies.appData}/> 
        : <Container className="mb-4 bg-white rounded-3">        
            <Button variant="secondary" onClick={
              () => showConfirm('Exit', 'Are you sure you want to go back?', () => {
                window.location.href = '/'
              })
            }><ArrowLeft/></Button>
            {menuItem === 'read' && <Read {...params}/>}
            {menuItem === 'find' && <Find {...params}/>}
            {menuItem === 'findo' && <FindO {...params}/>}
            {menuItem === 'which' && <Which {...params}/>}
            {menuItem === 'findall' && <FindAll {...params}/>}
            {menuItem === 'settings' && <Settings {...params}/>}
            {menuItem === 'help' && <Help {...params}/>}
          </Container>          
      }      
    </>
  );
}

export default App;
