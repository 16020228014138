import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import './App.css';
import { translate } from "./utils";

const ButtonGroupMenu = (props) => {

  return <><div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80vh',
      }}
    >
      <ButtonGroup vertical>
        {/* <Button onClick={() => props.onClick('read')} className="menuItem spacing">Read with Tajweed</Button>  */}
        <Button onClick={() => props.onClick('findo')} className="menuItem spacing">{translate(props.appData, 'Practice rule')}</Button>
        <Button onClick={() => props.onClick('find')} className="menuItem spacing">{translate(props.appData, 'Find rule')}</Button>
        <Button onClick={() => props.onClick('which')} className="menuItem spacing">{translate(props.appData, 'Which one?')}</Button>
        <Button onClick={() => props.onClick('findall')} className="menuItem spacing">{translate(props.appData, 'Find all!')}</Button>
      </ButtonGroup>

    
  </div>
        <footer>{translate(props.appData,`Please contact <a href="mailto:support@tajweedapp.com">support@tajweedapp.com</a> for any queries`, true)}</footer>               
  </>
}

export default ButtonGroupMenu;
