import React from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { mapRules } from './data';
import { Youtube } from 'react-bootstrap-icons';

const Help = (props) => {

  const { ReactGA } = props;

  function handleClick(rule) {
    ReactGA.event({
      category: 'Learn',
      action: rule.rule,
      value: 1
    });
    window.open(rule.video);
  }

  return (<><p>Each rule is shown below. Click on the Youtube icon to view a short video about the rule.</p><ButtonGroup vertical>
  {mapRules.map((rule) => {
    return <><Button style={{background: rule.color, textAlign: 'left'}}>{rule.title}&nbsp;&nbsp;&nbsp;<Youtube onClick={()=> handleClick(rule)}/></Button></>
  })}
  </ButtonGroup></>)
}

export default Help;
