import React,  { useState, useEffect, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import fireworks from 'fireworks';
import { forEachSeries } from 'p-iteration';
import Form from 'react-bootstrap/Form';
import { mapRules } from './data';
import { howManyTries, getLambdaUrl, getAvailableRules, translate, calculateBadge } from './utils';
import axios from 'axios';
import Loading from './Loading';
import { Question, Youtube } from 'react-bootstrap-icons';

const Which = (props) => {
  
  const { ReactGA, showModalWithMessage, data, updateCookie } = props;
  const maxAnswers = 3;
  const totalQuestions = 5;
  const [questionNo, setQuestionNo] = useState(0);
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(true);  
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [rule, setRule] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [questionClasses, setQuestionClasses] = useState('question');
  const [tries, setTries] = useState(0);
  const [scores] = useState([]);
  const [ inited, setInited ] = useState(false);
  const [canStillAnswer, setCanStillAnswer]= useState(true);
  
  const reloadRule = useCallback(async() => {
    setLoading(true);
    setQuestionNo(questionNo+1)
    setCanStillAnswer(true);
    setTries(1);
    const arules = getAvailableRules(data)
    const response = await axios
      .get(getLambdaUrl(data, arules))
    const tokens = response.data.tokens;
    setTokens(response.data.tokens);

    while (true) {
      const selectedIndex = Math.floor(Math.random()*tokens.length);
      if (tokens[selectedIndex].rule) {
        setSelectedIndex(selectedIndex);
        setRule(tokens[selectedIndex])
        break;
      }
    }
    setLoading(false); 
  }, [setTokens, setRule, setQuestionNo, questionNo, data])

  useEffect(() => {
    const fetchData = async () => {
      await reloadRule();
    }
    if (!inited) {
      setInited(true);
      const source = data.level === '0' ? 'Juz Amma' : 'the Quran';
      showModalWithMessage('Which one?', translate(data,`Please select which rule has been highlighted. There will be 5 questions and you will have 3 tries to find the correct answer. The ayats will come from %SOURCE%.`,false, {source: source}), true, () => fetchData(), 'Start');
    }    
}, [reloadRule, inited, showModalWithMessage, data]); // Or [] if effect doesn't need props or state

function handleClick(rule) {
  ReactGA.event({
    category: 'Learn',
    action: rule.rule,
    value: 1
  });
  window.open(rule.video);
}

function showLegend() {
  const body = (<><p>{translate(data,`Each rule is shown below. Click on the Youtube icon to view a short video about the rule.`)}</p><ButtonGroup vertical>
    {mapRules.map((rule) => {
      return <><Button onClick={()=> handleClick(rule)} style={{background: rule.color, textAlign: 'left'}}>{rule.title}&nbsp;&nbsp;&nbsp;<Youtube/></Button></>
    })}
    </ButtonGroup></>)
  props.showModalWithMessage('Tajweed Rules', body, null, null, null, true);
}

  async function submitAnswer() {
    if (tokens[selectedIndex].rule === answer) {
      setSelectedIndex(-1);
      window.scrollTo(0, 0)
      let range =  [...new Array(10)]
      scores.push(tries)
      setAnswer(null)
      setTimeout(async() => {
        await forEachSeries(range, async() => {
          const xrand = Math.floor(Math.random() * 100);
          const yrand = Math.floor(Math.random() * 100);
          fireworks({
            x: window.innerWidth * (xrand/100),
            y: window.innerHeight * (yrand/100),
            count: 250,
            bubbleSizeMaximum: 20,
            colors: ['#cc3333', '#4CAF50', '#81C784']
          })
        });   
      }, 500)
      await props.showResponse(true);
      setTimeout(() => {
        goNext();
      }, 1000)
    }
    else {
      setTries(tries + 1);
      setAnswer(null)
      if (tries >= maxAnswers) {
        setCanStillAnswer(false)
        scores.push(-1)
      }  
      else {
        setQuestionClasses("question animated");
        setTimeout(() => {
          setQuestionClasses("question");
        }, 1000);  
      }
    }
  }

  function goNext() {
    if (questionNo === totalQuestions) {
      const correct = scores.filter(x => x !== -1);
      const { title, message } = calculateBadge(data, updateCookie);
      if (correct.length === totalQuestions) {
        const extra = props.data.level === '0' ? translate(data,' Increase the level to Advanced to further test your skills') : '';
        props.showModalWithMessage('Finished!', translate(data,`You answered all %TOTAL% questions correctly!`, false, {total: totalQuestions}) + extra, false, () => props.selectMenu(null, title, message));
      }
      else {
        props.showModalWithMessage('Finished!', translate(data,`You correctly answered %CORRECT% out of %TOTAL% questions.`,false, {correct: correct.length, total: totalQuestions}), false, () => props.selectMenu(null, title, message));
      }
      
      ReactGA.event({
        category: 'Finished',
        action: 'Which',
        value: 1
      });      
    }
    else {
      reloadRule(false);
    }    
  }

  const questionText = rule && translate(data,`Which rule has been highlighted?`)
  const showCorrect = rule && translate(data,`The rule highlighted is `) + mapRules.find(x => x.rule === rule.rule).title + '.'

  return loading ? <Loading/> : (<>
  <Button class="pull-right" onClick={() => showLegend(true)}>
        <Question/>
      </Button>
    <Container>     
    <div className="questionArea">
      <h2>({questionNo}/{totalQuestions}) {questionText}</h2>      
      {canStillAnswer ? <p>{howManyTries(data, maxAnswers-tries+1)}</p> : <p className='wrong'>{showCorrect}</p>}
      <div className={questionClasses} id="questionDiv">
      {tokens && tokens.map((x,index) => {
        let classes = `text`;
        if (selectedIndex === index) {
          classes+=canStillAnswer ? ' selected' : ' selectedr';
        }
        return <span key={`q${index}`} id={`q${index}`} className={classes}>{x.text}</span>
      })}
      </div>
      {canStillAnswer ? <Form.Select className="selectAnswer" aria-label="Default select example" onChange={(ev) => {
          setAnswer(ev.target.value); 
        }}>
          <option>{translate(data,`Please select`)}</option>
          {mapRules.map(x => <option value={x.rule}>{x.title}</option>)}
        </Form.Select> : <Button variant="primary" onClick={goNext}>{translate(data, questionNo === totalQuestions ? `Finish` : `Next Question`)}</Button>}
        {answer && <Button variant="primary" onClick={submitAnswer}>{translate(data,`Submit`)}</Button>}
      </div> 
    </Container>
    </>
);
}

export default Which;
