import React,  { useState, useCallback, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import fireworks from 'fireworks';
import { mapRules } from './data';
import { getLambdaUrl, getAvailableRules, translate, howManyTries, calculateBadge } from './utils';
import { forEachSeries } from 'p-iteration';
import Loading from './Loading';
import axios from 'axios';
import { Question, Youtube } from 'react-bootstrap-icons';

const Find = (props) => {
  
  const { ReactGA, showModalWithMessage, data, updateCookie } = props;
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [loading, setLoading] = useState(true);  
  const [tokens, setTokens] = useState([]);
  const [rule, setRule] = useState(null);
  const [questionClasses, setQuestionClasses] = useState('question');
  const [tries, setTries] = useState(1);
  const [canStillAnswer, setCanStillAnswer]= useState(true);
  const maxAnswers = 3;
  const [ inited, setInited ] = useState(false);
  
  const reloadRule = useCallback(async() => {
    setLoading(true);
    const arules = getAvailableRules(data)
    const response = await axios
      .get(getLambdaUrl(data, arules))
    setTokens(response.data.tokens);
    const rules = response.data.tokens.filter(x => x.rule);
    setRule(rules[Math.floor(Math.random()*rules.length)])  
    setLoading(false); 
  }, [setTokens, setRule, data])

  useEffect(() => {
    const fetchData = async () => {
      await reloadRule();
    }
    if (!inited) {
      setInited(true);
      const source = data.level === '0' ? 'Juz Amma' : 'the Quran';
      showModalWithMessage('Find all!', translate(data,`Find all instances of the rule in the following ayah. You will have 3 tries. If an area of the ayah is not clickable, that means there are no rules there. The ayats will come from %SOURCE%.`, false, {source: source}), true, () => fetchData(), 'Start');
    }    
}, [reloadRule, inited, setInited, showModalWithMessage, data]); // Or [] if effect doesn't need props or state

function handleClick(rule) {
  ReactGA.event({
    category: 'Learn',
    action: rule.rule,
    value: 1
  });
  window.open(rule.video);
}

function showLegend() {
  const body = (<><p>${translate(data, `Each rule is shown below. Click on the Youtube icon to view a short video about the rule.`)}</p><ButtonGroup vertical>
    {mapRules.map((rule) => {
      return <><Button onClick={()=> handleClick(rule)} style={{background: rule.color, textAlign: 'left'}}>{rule.title}&nbsp;&nbsp;&nbsp;<Youtube/></Button></>
    })}
    </ButtonGroup></>)
  props.showModalWithMessage('Tajweed Rules', body, null, null, null, true);
}

  async function submitAnswer() {
    setTries(tries+1);
    const correct = tokens.filter(x => x.rule === rule.rule).length === 
    tokens.filter((x, i) => selectedIndex.indexOf(i) !== -1).filter(x => x.rule === rule.rule).length
    if (correct) {
      setSelectedIndex([]);
      let range =  [...new Array(10)]
      props.showResponse(true);
      const extra = data.level === '0' ? translate(data,' Increase the level to Advanced to further test your skills.') : '';
      const text = tries > 1
        ? translate(data, `Masha Allah! It took you %TRIES% tries.`, false, {tries: tries})
        : translate(data, `Masha Allah! It took you 1 try.`)
      const { title, message } = calculateBadge(data, updateCookie);
      props.showModalWithMessage('Finished!', text + extra, false, () => props.selectMenu(null, title, message));
      await forEachSeries(range, async() => {
        const xrand = Math.floor(Math.random() * 100);
        const yrand = Math.floor(Math.random() * 100);
        fireworks({
          x: window.innerWidth * (xrand/100),
          y: window.innerHeight * (yrand/100),
          count: 250,
          bubbleSizeMaximum: 20,
          colors: ['#cc3333', '#4CAF50', '#81C784']
        })
      });  
      ReactGA.event({
        category: 'Finished',
        action: 'Which',
        value: 1
      });            
    }
    else {
      setSelectedIndex([]);
      if (tries >= maxAnswers) {
        setCanStillAnswer(false)
        props.showModalWithMessage('Incorrect', `Unfortunately you have run out of tries. Please try again.`, false, () => props.selectMenu());
      }  
      else {
        setQuestionClasses("question animated");
        setTimeout(() => {
          setQuestionClasses("question");
        }, 1000);  
      }      
    }
  }

  function selectSpan(index) {
    if (index === -1) {
      setSelectedIndex([]);
    }
    else if (tokens[index].rule) {
      if (selectedIndex.indexOf(index) !== -1) {
        setSelectedIndex(selectedIndex.filter(x => x !== index));  
      }
      else {
        setSelectedIndex(selectedIndex.concat(index));  
      }      
    }
  }

  const questionText = rule && (`Find all instances of ` + mapRules.find(x => x.rule === rule.rule).title)

  return loading ? <Loading/> : (<>
  <Button class="pull-right" onClick={() => showLegend(true)}>
        <Question/>
      </Button>
    <Container>     
    <div className="questionArea">
      <h2>{questionText}</h2>      
      {canStillAnswer && <p>{howManyTries(data, maxAnswers-tries+1)}.</p>}
      <div className={questionClasses} id="questionDiv">
      {tokens && tokens.map((x,index) => {
        let classes = `text`;
        if (selectedIndex.indexOf(index) !== -1) {
          classes+=' selected';
        }
        return <span key={`q${index}`} id={`q${index}`} className={classes} onClick={() => selectSpan(index)}>{x.text}</span>
      })}
      </div>
        {selectedIndex.length > 0 && <Button className="m-3" variant="secondary" onClick={() => selectSpan(-1)}>{translate(data,`Clear`)}</Button>}
        {selectedIndex.length > 0 && <Button variant="primary" onClick={submitAnswer}>{translate(data,`Submit`)}</Button>}
      </div> 
    </Container>
    </>
);
}

export default Find;
